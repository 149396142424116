import axios from "axios";
import ConfigHelper from "../config/Config";
import tokenHelper from "../helpers/tokenHelper";

const apiClient = axios.create({
  baseURL: ConfigHelper.getApiUrl(),
});

apiClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers["Authorization"] = `Bearer ${tokenHelper.getToken()}`;
  }
  return config;
});

export default apiClient;
