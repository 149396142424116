import DataHelper from "../../helpers/data.helper";
import InitClassLogic from "./Class.logic";
import styles from "./Class.module.css"

const ClassComponent: React.FC = () => {
    const { selectedClients, clients, data, trainingTypeRef, trainingsTypes, onSave, onChange, addClient, removeSelectedClient } = InitClassLogic();
    return (
        <>
            <h1 className="text-align-center">Evidencija treninga</h1>
            <button type="button" onClick={onSave}>Sačuvaj</button>
            <br></br>
            <br></br>
            <label htmlFor="trainingType">
                Tip treninga
            </label>
            <br></br>
            <select id="trainingType" aria-label={"Izaberi tip treninga"} ref={trainingTypeRef} onChange={onChange} name="trainingType">
                {trainingsTypes.map((t) => (
                    <option key={t} value={t}>{t}</option>
                ))}
            </select>
            <br></br>
            <br></br>
            <label htmlFor="client">
                Pretraga klijenata
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChange} name="client"></input>
            <br></br>
            <br></br>
            {selectedClients.map((c) =>
                <div key={c.id}><button className={(data.trainingType === trainingsTypes[0] ? c[DataHelper.trainingTypesIdsClientCounters[1]] : data.trainingType === trainingsTypes[1] ? c[DataHelper.trainingTypesIdsClientCounters[2]] + c[DataHelper.trainingTypesIdsClientCounters[3]] : data.trainingType === trainingsTypes[2] ? c[DataHelper.trainingTypesIdsClientCounters[4]] + c[DataHelper.trainingTypesIdsClientCounters[5]] + c[DataHelper.trainingTypesIdsClientCounters[6]] : 0) < 0 ? styles.red : ""} type="button" key={c.id} onClick={() => removeSelectedClient(c)}>{c.firstName} {c.lastName}  [{data.trainingType === trainingsTypes[0] ? c[DataHelper.trainingTypesIdsClientCounters[1]] : data.trainingType === trainingsTypes[1] ? c[DataHelper.trainingTypesIdsClientCounters[2]] + c[DataHelper.trainingTypesIdsClientCounters[3]] : data.trainingType === trainingsTypes[2] ? c[DataHelper.trainingTypesIdsClientCounters[4]] + c[DataHelper.trainingTypesIdsClientCounters[5]] + c[DataHelper.trainingTypesIdsClientCounters[6]] : 0}]</button>
                    <br key={c.id + "a"}></br></div>
            )
            }
            {selectedClients.length > 0 && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z").includes(data.client.toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z")) ? (
                <div key={c.id}><button className={(data.trainingType === trainingsTypes[0] ? c[DataHelper.trainingTypesIdsClientCounters[1]] : data.trainingType === trainingsTypes[1] ? c[DataHelper.trainingTypesIdsClientCounters[2]] + c[DataHelper.trainingTypesIdsClientCounters[3]] : data.trainingType === trainingsTypes[2] ? c[DataHelper.trainingTypesIdsClientCounters[4]] + c[DataHelper.trainingTypesIdsClientCounters[5]] + c[DataHelper.trainingTypesIdsClientCounters[6]] : 0) < 0 ? styles.red : ""} type="button" key={c.id} onClick={() => addClient(c)}>{c.firstName} {c.lastName} [{data.trainingType === trainingsTypes[0] ? c[DataHelper.trainingTypesIdsClientCounters[1]] : data.trainingType === trainingsTypes[1] ? c[DataHelper.trainingTypesIdsClientCounters[2]] + c[DataHelper.trainingTypesIdsClientCounters[3]] : data.trainingType === trainingsTypes[2] ? c[DataHelper.trainingTypesIdsClientCounters[4]] + c[DataHelper.trainingTypesIdsClientCounters[5]] + c[DataHelper.trainingTypesIdsClientCounters[6]] : 0}]</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br>
            <button type="button" onClick={onSave}>Sačuvaj</button>
        </>
    );
}

export default ClassComponent;