import client from "../client";

const getBookings = () => client.get<any>(`/bookings`);

const getBookingsForClients = () => client.get<any>(`/bookings-for-clients`);

const book = (props: any) => client.post<any>(`/bookings`, props);

const cancel = (props: any) => client.post<any>(`/bookings/cancel/${props.id}`);

const trainerCancel = (props: any) =>
  client.post<any>(`/bookings/trainer/cancel/${props.id}`);

const trainerBook = (props: any) =>
  client.post<any>(`/bookings/trainer/book/${props.id}`);

const bookingApi = {
  getBookings,
  getBookingsForClients,
  book,
  cancel,
  trainerCancel,
  trainerBook,
};
export default bookingApi;
