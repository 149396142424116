import { useEffect, useState } from "react";
import usersApi from "../../api/user/user";

const InitSalaryLogic = () => {
  const [user, setUser] = useState<any>();

  const loadUser = () => {
    usersApi
      .getMe()
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadUser();
  }, []);

  return {
    user,
  };
};

export default InitSalaryLogic;
