import { useEffect, useState } from "react";
import bookingApi from "../../api/booking/booking";

const InitTrainingsLogic = () => {
  const [trainings, setTrainings] = useState<any[]>([]);

  useEffect(() => {
    loadTrainings();
  }, []);

  const loadTrainings = () => {
    bookingApi
      .getBookings()
      .then((response) => {
        if (response.status === 200) {
          setTrainings(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onBook = (data: any) => {
    bookingApi
      .trainerBook(data)
      .then(() => {
        loadTrainings();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onCancel = (id: number) => {
    bookingApi
      .trainerCancel({ id })
      .then(() => {
        loadTrainings();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  return {
    trainings,
    onBook,
    onCancel,
  };
};

export default InitTrainingsLogic;
