import { useState } from "react";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";
import DataHandlerHelper from "../../helpers/DataHandler.helper";

const InitStopAllLogic = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  /* const [clients, setClients] = useState<any[]>([]);

  const loadClients = () => {
    clientsApi
      .getClients()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadClients();
  }, []); */

  const onSave = () => {
    if (new Date(data.fromDate).toString() === "Invalid Date") {
      alert("Neispravan datum Od");
      return;
    }
    if (new Date(data.toDate).toString() === "Invalid Date") {
      alert("Neispravan datum Do");
      return;
    }
    if (!window.confirm("Sigurno želite da sačuvate?")) return;
    clientsApi
      .stopAll({
        fromDate: new Date(data.fromDate).toISOString(),
        toDate: new Date(data.toDate).toISOString(),
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          navigate("/home");
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  return {
    data,
    onSave,
    onChange,
  };
};

export default InitStopAllLogic;
