import DataHandlerHelper from "../../helpers/DataHandler.helper";
import InitLoginClientLogic from "./LoginClient.logic";

const LoginClientComponent: React.FC = () => {
    const { data, usernameRef, passwordRef, onSubmit, onChange } = InitLoginClientLogic();
    return (
        <>
            <h1 className="text-align-center">RESET</h1>
            <h3 className="text-align-center">Prijava na sistem</h3>
            <br></br>
            <br></br>
            <label htmlFor="username">
                Korisničko ime
            </label>
            <br></br>
            <input placeholder="Unesi korisničko ime..." id="username" type={"text"} ref={usernameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => passwordRef.current.focus())} value={data.username} onChange={onChange} name="username" required></input>
            <br></br>
            <br></br>
            <label htmlFor="password">
                Lozinka
            </label>
            <br></br>
            <input placeholder="Unesi lozinku..." id="password" type={"text"} ref={passwordRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, onSubmit)} value={data.password} onChange={onChange} name="password" required></input>
            <br></br>
            <br></br>
            <button type="button" onClick={onSubmit}>Prijavi se</button>
        </>
    );
}

export default LoginClientComponent;