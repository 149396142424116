import DataHelper from "../../helpers/data.helper";
import InitClientsLogic from "./Clients.logic";

const ClientsComponent: React.FC = () => {
    const { selectedClient, clients, data, onChange, selectClient, removeSelectedClient } = InitClientsLogic();
    return (
        <>
            <h1 className="text-align-center">Klijenti</h1>
            <label htmlFor="client">
                Pretraga klijenata
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChange} name="client"></input>
            <br></br>
            <br></br>
            {selectedClient !== undefined &&
                <div key={selectedClient.id}><button type="button" key={selectedClient.id} onClick={() => removeSelectedClient(selectedClient)}>{selectedClient.firstName} {selectedClient.lastName}</button>
                    <br key={selectedClient.id + "a"}></br></div>
            }
            {selectedClient !== undefined && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z").includes(data.client.toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z")) ? (
                <div key={c.id}><button type="button" key={c.id} onClick={() => selectClient(c)}>{c.firstName} {c.lastName}</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br>
            {selectedClient !== undefined && <>
                <div className="text-align-center">
                    <h1>{selectedClient?.firstName} {selectedClient?.lastName}</h1>
                    <p>{selectedClient?.phone}</p>
                </div>
                <br></br>
                <div className="text-align-center">
                    <h3>Uplate</h3>
                    {selectedClient?.payments?.map((payment: any) => (
                        <div key={payment.id}>
                            <br></br>
                            <p>{new Date(payment.date).toLocaleString()}</p>
                            <p>{DataHelper.trainingTypesIdsTrainingTypes[payment.trainingTypeId]}</p>
                            <p>{payment.price}</p>
                        </div>
                    )
                    )}
                    <br></br>
                    <h3>Treninzi</h3>
                    {selectedClient?.trainings?.map((training: any) => (
                        <div key={training.id}>
                            <br></br>
                            <p>{new Date(training.date).toLocaleString()}</p>
                            <p>{DataHelper.trainingTypesIdsTrainingTypesForUser[training.trainingTypeId]}</p>
                            <p>Klijenti:</p>
                            {training.clients?.map((client: any) => (
                                <p key={client.id}>{client.firstName} {client.lastName}</p>
                            ))}
                        </div>
                    )
                    )}
                </div>
            </>}
        </>
    );
}

export default ClientsComponent;