import React, { useEffect } from 'react';

const WebSocketComponent = ({ userId }: { userId: string }) => {
    useEffect(() => {
        const socket = new WebSocket('wss://resetpilates.rs/ws/');

        socket.onopen = () => {
            console.log('WebSocket connected');
            // Autentifikacija korisnika pomoću userId
            socket.send(JSON.stringify({ type: 'authenticate', userId }));
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Message received:', data.message);
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        return () => {
            socket.close();
        };
    }, [userId]);
    return <></>
    // return <div>WebSocket active for user: {userId}</div>;
};

export default WebSocketComponent;
