import React from 'react'
import IErrorPage from '../../interfaces/errorPage';


const ErrorPage: React.FunctionComponent<IErrorPage> = props => {

    return (
        <div>
            <h1>{`Error ${props.code}`}</h1>
            <h2>{`${props.error} `}</h2>
        </div>
    );
}

export default ErrorPage;