import DataHelper from "../../helpers/data.helper";
import InitSalaryLogic from "./Salary.logic";

const SalaryComponent: React.FC = () => {
    const { user } = InitSalaryLogic();
    return (
        <>
            <div className="text-align-center">
                <h1>Moja plata</h1>
                <h3>{user?.firstName} {user?.lastName}</h3>
                <p>{user?.salary} dinara</p>
            </div>
            <br></br>
            <div className="text-align-center">
                <h3>Moji treninzi</h3>
                {user?.trainings.map((training: any) => (
                    <div key={training.id}>
                        <br></br>
                        <p>{new Date(training.date).toLocaleString()}</p>
                        <p>{DataHelper.trainingTypesIdsTrainingTypesForUser[training.trainingType.id]}</p>
                        <p>Klijenti:</p>
                        {training.clients?.map((client: any) => (
                            <p key={client.id}>{client.firstName} {client.lastName}</p>
                        ))}
                    </div>
                )
                )}

            </div>
        </>
    );
}

export default SalaryComponent;