import { useEffect, useRef, useState } from "react";
import DataHandlerHelper from "../../helpers/DataHandler.helper";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";
import classApi from "../../api/class/class";

const InitClassLogic = () => {
  const navigate = useNavigate();
  const [trainingsTypes] = useState<any[]>([
    "Reformer grupa",
    "Individualni",
    "Duo",
  ]);
  const trainingTypeRef = useRef<any>();
  const [data, setData] = useState<any>({
    client: "",
    trainingType: "Reformer grupa",
  });
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  /* const loadTrainingsTypes = () => {
    trainingTypeApi
      .getTrainingTypes()
      .then((response) => {
        if (response.status === 200) {
          setTrainingsTypes(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  }; */

  const loadClients = () => {
    clientsApi
      .getClients()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onSave = () => {
    if (!window.confirm("Sigurno želite da sačuvate ovaj trening?")) return;
    classApi
      .saveClass({
        trainingType: trainingTypeRef.current.value,
        clients: selectedClients,
      })
      .then((response) => {
        if (response.status === 204) {
          navigate("/home");
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  const addClient = (client: any) => {
    clients.splice(clients.indexOf(client), 1);
    // setClients([...clients]);
    setSelectedClients([...selectedClients, client]);
    setData({ ...data, client: "" });
  };

  const removeSelectedClient = (client: any) => {
    let removedSelectedClient = selectedClients.splice(
      selectedClients.indexOf(client),
      1
    );
    setSelectedClients([...selectedClients]);
    setClients([...clients, ...removedSelectedClient]);
  };

  useEffect(() => {
    // loadTrainingsTypes();
    loadClients();
  }, []);

  return {
    selectedClients,
    clients,
    data,
    trainingTypeRef,
    trainingsTypes,
    onSave,
    onChange,
    addClient,
    removeSelectedClient,
  };
};

export default InitClassLogic;
