import ConfigHelper from "../../config/Config";
import InitHomeLogic from "./Home.logic";

const HomeComponent: React.FC = () => {
    const { user, onClass, onPayment, onClientRegistration, onClientUpdate, onPayments, onClientsInMinus, onStopClient, onStopAll, onClients, onSalaries, onSalary, onLogout, onTrainings } = InitHomeLogic();
    return (
        <>
            <h1 className="text-align-center">RESET</h1>
            {/* {user?.username !== "milica" && <> */}
            <button type="button" onClick={onClass}>Evidentiraj trening</button>
            <br></br>
            <br></br>
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onTrainings}>Treninzi</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onPayment}>Uplata</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onClientRegistration}>Novi klijent</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onClientUpdate}>Izmena klijenta</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onPayments}>Pregled uplata</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onClientsInMinus}>Pregled dužnika</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onStopClient}>Stopiranje paketa klijentu</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onStopAll}>Stopiranje paketa svima</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onClients}>Klijenti</button>
                <br></br>
                <br></br>
            </>
            }
            {user?.username === ConfigHelper.getAdminUsername() && <>
                <button type="button" onClick={onSalaries}>Treneri</button>
                <br></br>
                <br></br>
            </>
            }
            {/* </>
            } */}
            <button type="button" onClick={onSalary}>Moja plata</button>
            <br></br>
            <br></br>
            <button type="button" onClick={onLogout}>Odjavi se</button>
        </>
    );
}

export default HomeComponent;