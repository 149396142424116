import client from "../client";

const getMe = () => client.get<any>(`/users/get/me`);

const getUsers = () => client.get<any>(`/users/get/salaries`);

const usersApi = {
  getMe,
  getUsers,
};
export default usersApi;
