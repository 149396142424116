import client from "../client";

const getClients = () => client.get<any>(`/clients`);

const getClientsWithPaymentsAndTrainings = () =>
  client.get<any>(`/clients/all`);

const registerClient = (props: any) => client.post<any>(`/clients`, props);

const updateClient = (props: any, id: number) =>
  client.patch<any>(`/clients/${id}`, props);

const stop = (props: any) => client.post<any>(`/clients/stop`, props);

const stopAll = (props: any) => client.post<any>(`/clients/stop-all`, props);

const getMe = () => client.get<any>(`/clients/get/me`);

const clientsApi = {
  getClients,
  getClientsWithPaymentsAndTrainings,
  registerClient,
  updateClient,
  stop,
  stopAll,
  getMe,
};
export default clientsApi;
