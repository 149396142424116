import DataHelper from "../../helpers/data.helper";
import InitPaymentLogic from "./Payment.logic";

const PaymentComponent: React.FC = () => {
    const { selectedClients, clients, data, trainingTypeRef, trainingsTypes, onSave, onChange, addClient, removeSelectedClient } = InitPaymentLogic();
    return (
        <>
            <h1 className="text-align-center">Uplata</h1>
            <button type="button" onClick={onSave}>Sačuvaj</button>
            <br></br><br></br>
            <label htmlFor="trainingType">
                Tip treninga
            </label>
            <br></br>
            <select id="trainingType" aria-label={"Izaberi tip treninga"} ref={trainingTypeRef} onChange={onChange} name="trainingType">
                {trainingsTypes.map((t) => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                ))}
            </select>
            {(data.trainingType === "3" || data.trainingType === "5") && <>
                <br></br>
                <br></br>
                <label htmlFor="quantity">
                    Količina
                </label>
                <br></br>
                <input id="quantity" type={"number"} min={1} step={1} inputMode={"numeric"} value={data.quantity} onChange={onChange} name="quantity"></input>
            </>}
            <br></br>
            <br></br>
            <label htmlFor="price">
                Iznos u dinarima
            </label>
            <br></br>
            <input id="price" type={"number"} min={0} step={1} inputMode={"numeric"} value={data.price} onChange={onChange} name="price"></input>
            <br></br>
            <br></br>
            <label htmlFor="client">
                Pretraga klijenata
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChange} name="client"></input>
            <br></br>
            <br></br>
            {selectedClients.map((c) =>
                <div key={c.id}><button type="button" key={c.id} onClick={() => removeSelectedClient(c)}>{c.firstName} {c.lastName} [{(c[DataHelper.trainingTypesIdsClientPrices[+data.trainingType]] * DataHelper.trainingTypesIdsQuantities[+data.trainingType]) * (DataHelper.trainingTypesIdsQuantities[+data.trainingType] === 1 ? data.quantity : 1)}]</button>
                    <br key={c.id + "a"}></br></div>
            )
            }
            {selectedClients.length > 0 && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z").includes(data.client.toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z")) ? (
                <div key={c.id}><button type="button" key={c.id} onClick={() => addClient(c)}>{c.firstName} {c.lastName} [{(c[DataHelper.trainingTypesIdsClientPrices[+data.trainingType]] * DataHelper.trainingTypesIdsQuantities[+data.trainingType]) * (DataHelper.trainingTypesIdsQuantities[+data.trainingType] === 1 ? data.quantity : 1)}]</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br><br></br>
            <button type="button" onClick={onSave}>Sačuvaj</button>
        </>
    );
}

export default PaymentComponent;