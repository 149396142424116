import IRoute from "../interfaces/route";
import BookingsPage from "../pages/bookings/Bookings.page";
import ClassPage from "../pages/class/Class.page";
import ClientRegistrationPage from "../pages/clientRegistration/ClientRegistration.page";
import ClientUpdatePage from "../pages/clientUpdate/ClientUpdate.page";
import ClientsPage from "../pages/clients/Clients.page";
import ClientsInMinusPage from "../pages/clientsInMinus/ClientsInMinus.page";
import HomePage from "../pages/home/Home.page";
import HomeClientPage from "../pages/homeClient/HomeClient.page";
import LoginClientPage from "../pages/loginClient/LoginClient.page";
import PaymentPage from "../pages/payment/Payment.page";
import PaymentsPage from "../pages/payments/Payments.page";
import SalariesPage from "../pages/salaries/Salaries.page";
import SalaryPage from "../pages/salary/Salary.page";
import StopAllPage from "../pages/stopAll/StopAll.page";
import StopClientPage from "../pages/stopClient/StopClient.page";
import TrainingsPage from "../pages/trainings/Trainings.page";

const routes: IRoute[] = [
  {
    path: "/",
    component: LoginClientPage,
  },
  {
    path: "/home-client",
    component: HomeClientPage,
    auth: ["ROLE_CLIENT"],
  },
  {
    path: "/bookings",
    component: BookingsPage,
    auth: ["ROLE_CLIENT"],
  },
  {
    path: "/home",
    component: HomePage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/class",
    component: ClassPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/payment",
    component: PaymentPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/client-registration",
    component: ClientRegistrationPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/payments",
    component: PaymentsPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/clients-in-minus",
    component: ClientsInMinusPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/stop-client",
    component: StopClientPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/stop-all",
    component: StopAllPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/clients",
    component: ClientsPage,
    auth: ["ROLE_ADMIN"],
  },
  {
    path: "/salary",
    component: SalaryPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  {
    path: "/client-update",
    component: ClientUpdatePage,
    auth: ["ROLE_ADMIN"],
  },
  {
    path: "/salaries",
    component: SalariesPage,
    auth: ["ROLE_ADMIN"],
  },
  {
    path: "/trainings",
    component: TrainingsPage,
    auth: ["ROLE_USER", "ROLE_ADMIN"],
  },
  /* {
    path: "/login",
    component: LoginPage,
  }, */
  {
    path: "*",
    // component: ErrorPage,
    // props: { code: 404, error: "Page not found" },
    // allowLoggedUser: true,
    component: LoginClientPage,
  },
];

export default routes;
