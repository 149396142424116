import { useNavigate } from "react-router-dom";
import tokenHelper from "../../helpers/tokenHelper";
import { useEffect, useState } from "react";
import clientsApi from "../../api/client/client";
import bookingApi from "../../api/booking/booking";
import apiClient from "../../api/client";

const InitHomeClientLogic = () => {
  const navigate = useNavigate();

  const [client, setClient] = useState<any>();

  const loadClient = () => {
    clientsApi
      .getMe()
      .then((response) => {
        if (response.status === 200) {
          setClient(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadClient();
  }, []);

  const onLogout = async () => {
    await apiClient.post<any>(`/delete-subscription`);
    tokenHelper.removeToken();
    navigate("/");
  };

  const onTrainings = () => {
    navigate("/bookings");
  };

  const onCancel = (id: number) => {
    bookingApi
      .cancel({ id })
      .then(() => {
        loadClient();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  return {
    client,
    onLogout,
    onTrainings,
    onCancel,
  };
};

export default InitHomeClientLogic;
