import DataHandlerHelper from "../../helpers/DataHandler.helper";
import InitClientUpdateLogic from "./ClientUpdate.logic";

const ClientUpdateComponent: React.FC = () => {
    const { selectedClient, clients, data, firstNameRef, lastNameRef, phoneRef, priceIndRef, price1IndRef, priceDuoRef, price1DuoRef, priceFamilyDuoRef, priceRefRef, onSubmit, onChange, onChangeSearch, selectClient, removeSelectedClient } = InitClientUpdateLogic();
    return (
        <>
            <h1 className="text-align-center">Izmena klijenta</h1>
            <button type="button" onClick={onSubmit}>Sačuvaj</button>
            <br></br>
            <br></br>
            <label htmlFor="client">
                Pretraga klijenata
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChangeSearch} name="client"></input>
            <br></br>
            <br></br>
            {selectedClient !== undefined &&
                <div key={selectedClient.id}><button type="button" key={selectedClient.id} onClick={() => removeSelectedClient(selectedClient)}>{selectedClient.firstName} {selectedClient.lastName}</button>
                    <br key={selectedClient.id + "a"}></br></div>
            }
            {selectedClient !== undefined && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z").includes(data.client.toLowerCase().replaceAll("ć", "c").replaceAll("č", "c").replaceAll("š", "s").replaceAll("ž", "z")) ? (
                <div key={c.id}><button type="button" key={c.id} onClick={() => selectClient(c)}>{c.firstName} {c.lastName}</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br>
            {selectedClient !== undefined && <>
                <label htmlFor="firstName">
                    Ime
                </label>
                <br></br>
                <input placeholder="Unesi ime..." id="firstName" type={"text"} ref={firstNameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => lastNameRef.current.focus())} value={selectedClient.firstName} onChange={onChange} name="firstName" required></input>
                <br></br>
                <br></br>
                <label htmlFor="lastName">
                    Prezime
                </label>
                <br></br>
                <input placeholder="Unesi prezime..." id="lastName" type={"text"} ref={lastNameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => phoneRef.current.focus())} value={selectedClient.lastName} onChange={onChange} name="lastName" required></input>
                <br></br>
                <br></br>
                <label htmlFor="phone">
                    Broj telefona
                </label>
                <br></br>
                <input placeholder="Unesi broj telefona" id="phone" type={"text"} ref={phoneRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceIndRef.current.focus())} value={selectedClient.phone} onChange={onChange} name="phone" required></input>
                <br></br>
                <br></br>
                <label htmlFor="priceInd">
                    Cena jednog individualnog treninga u paketu
                </label>
                <br></br>
                <input id="priceInd" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceIndRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => price1IndRef.current.focus())} value={selectedClient.priceInd} onChange={onChange} name="priceInd" required></input>
                <br></br>
                <br></br>
                <label htmlFor="price1Ind">
                    Cena jednog individualnog treninga
                </label>
                <br></br>
                <input id="price1Ind" type={"number"} inputMode={"numeric"} min={0} step={1} ref={price1IndRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceDuoRef.current.focus())} value={selectedClient.price1Ind} onChange={onChange} name="price1Ind" required></input>
                <br></br>
                <br></br>
                <label htmlFor="priceDuo">
                    Cena jednog duo treninga iz paketa
                </label>
                <br></br>
                <input id="priceDuo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceDuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => price1DuoRef.current.focus())} value={selectedClient.priceDuo} onChange={onChange} name="priceDuo" required></input>
                <br></br>
                <br></br>
                <label htmlFor="price1Duo">
                    Cena jednog duo treninga
                </label>
                <br></br>
                <input id="price1Duo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={price1DuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceFamilyDuoRef.current.focus())} value={selectedClient.price1Duo} onChange={onChange} name="price1Duo" required></input>
                <br></br>
                <br></br>
                <label htmlFor="priceFamilyDuo">
                    Cena jednog duo treninga iz porodičnog paketa
                </label>
                <br></br>
                <input id="priceFamilyDuo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceFamilyDuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceRefRef.current.focus())} value={selectedClient.priceFamilyDuo} onChange={onChange} name="priceFamilyDuo" required></input>
                <br></br>
                <br></br>
                <label htmlFor="priceRef">
                    Cena jednog reformer treninga iz paketa
                </label>
                <br></br>
                <input id="priceRef" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceRefRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, onSubmit)} value={selectedClient.priceRef} onChange={onChange} name="priceRef" required></input>
                <br></br>
                <br></br>
            </>}
            <button type="button" onClick={onSubmit}>Sačuvaj</button>
        </>
    );
}

export default ClientUpdateComponent;