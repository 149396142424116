import { Fragment, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import PrivateRouteComponent from './components/PrivateRoute/PrivateRoute.component';
import routes from './config/routes';
import WebSocketComponent from './components/Websocket/WebSocket.component';
import { useUser } from './context/UserContext';
import ShareSafariIcon from './icons/ShareSafariIcon';
import { FaArrowDown } from 'react-icons/fa';

function App() {
  const { userId } = useUser();

  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  useEffect(() => {
    setIsStandalone(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  useEffect(() => {
    if (isIos()) {
      setIsInstallable(true); // Always show install instructions for iOS
    } else {
      const handler = (e: any) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setIsInstallable(true);
      };

      window.addEventListener('beforeinstallprompt', handler);

      return () => {
        window.removeEventListener('beforeinstallprompt', handler);
      };
    }
  }, []);


  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <Fragment>
      {userId && (isStandalone || isIos()) && !isInstallable ? <WebSocketComponent userId={userId} /> : <></>}
      {!isStandalone && isInstallable && (

        <div className="install-prompt-overlay">
          <div className="install-prompt-background">
            <img src="/logo512.png" alt="RESET logo" className="background-logo" />
          </div>
          <div className="install-prompt-modal">
            <div className="modal-header">
              <img src="/logo512.png" alt="RESET logo" className="modal-logo" />
              <h1 className="modal-title">RESET</h1>
            </div>
            <hr className="modal-divider" />
            {!isIos() ? <button className="install-button" onClick={handleInstallClick}>
              Instaliraj aplikaciju
            </button> : <div className="install-instruction">
              <h2>Instaliraj aplikaciju:</h2>
              <ol>
                <li>
                  <span className="share-icon"><ShareSafariIcon></ShareSafariIcon></span>
                </li>
                <li>
                  <div className="add-to-home-screen">Add to Home Screen</div>
                </li>
              </ol>
              <div className="arrow-container">
                <FaArrowDown className="down-arrow-icon" />
              </div>
            </div>}
          </div>
        </div>
      )
      }
      {
        true && (
          <Routes>
            {
              routes.map((route, index) => {
                return (
                  <Route key={index} path={route.path} element={<PrivateRouteComponent route={route} />} />
                );
              })
            }
          </Routes>
        )
      }
    </Fragment >
  );
}

export default App;
