import InitStopClientLogic from "./StopClient.logic";

const StopClientComponent: React.FC = () => {
    const { selectedClients, clients, data, onSave, onChange, addClient, removeSelectedClient } = InitStopClientLogic();
    return (
        <>
            <h1 className="text-align-center">Stopiraj paket klijentu</h1>
            <button type="button" onClick={onSave}>Sačuvaj</button>
            <br></br><br></br>
            <label htmlFor="fromDate">
                Od
            </label>
            <br></br>
            <input id="fromDate" type={"date"} value={data.fromDate} onChange={onChange} name="fromDate"></input>
            <br></br>
            <br></br>
            <label htmlFor="toDate">
                Do
            </label>
            <br></br>
            <input id="toDate" type={"date"} value={data.toDate} onChange={onChange} name="toDate"></input>
            <br></br>
            <br></br>
            <label htmlFor="client">
                Pretraga klijenata
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChange} name="client"></input>
            <br></br>
            <br></br>
            {selectedClients.map((c) =>
                <div key={c.id}><button type="button" key={c.id} onClick={() => removeSelectedClient(c)}>{c.firstName} {c.lastName}</button>
                    <br key={c.id + "a"}></br></div>
            )
            }
            {selectedClients.length > 0 && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć","c").replaceAll("č","c").replaceAll("š","s").replaceAll("ž","z").includes(data.client.toLowerCase().replaceAll("ć","c").replaceAll("č","c").replaceAll("š","s").replaceAll("ž","z")) ? (
                <div key={c.id}><button type="button" key={c.id} onClick={() => addClient(c)}>{c.firstName} {c.lastName}</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br><br></br>
            <button type="button" onClick={onSave}>Sačuvaj</button>
        </>
    );
}

export default StopClientComponent;