import DataHelper from "../../helpers/data.helper";
import InitSalariesLogic from "./Salaries.logic";

const SalariesComponent: React.FC = () => {
    const { selectedClient, clients, data, onChange, selectClient, removeSelectedClient } = InitSalariesLogic();
    return (
        <>
            <h1 className="text-align-center">Treneri</h1>
            <label htmlFor="client">
                Pretraga trenera
            </label>
            <br></br>
            <input placeholder="Traži po imenu ili prezimenu..." id="client" type={"text"} value={data.client} onChange={onChange} name="client"></input>
            <br></br>
            <br></br>
            {selectedClient !== undefined &&
                <div key={selectedClient.id}><button type="button" key={selectedClient.id} onClick={() => removeSelectedClient(selectedClient)}>{selectedClient.firstName} {selectedClient.lastName}</button>
                    <br key={selectedClient.id + "a"}></br></div>
            }
            {selectedClient !== undefined && <><br></br><hr></hr><br></br></>}
            {clients.map((c) => c.firstName.concat(" ", c.lastName).toLowerCase().replaceAll("ć","c").replaceAll("č","c").replaceAll("š","s").replaceAll("ž","z").includes(data.client.toLowerCase().replaceAll("ć","c").replaceAll("č","c").replaceAll("š","s").replaceAll("ž","z")) ? (
                <div key={c.id}><button type="button" key={c.id} onClick={() => selectClient(c)}>{c.firstName} {c.lastName}</button>
                    <br key={c.id + "a"}></br></div>
            ) : "")
            }
            <br></br>
            {selectedClient !== undefined && <>
                <div className="text-align-center">
                    <h1>{selectedClient?.firstName} {selectedClient?.lastName}</h1>
                    <p>{selectedClient?.salary} dinara</p>
                </div>
                <br></br>
                <div className="text-align-center">
                    <h3>Treninzi</h3>
                    {selectedClient?.trainings.map((training: any) => (
                        <div key={training.id}>
                            <br></br>
                            <p>{new Date(training.date).toLocaleString()}</p>
                            <p>{DataHelper.trainingTypesIdsTrainingTypesForUser[training.trainingType.id]}</p>
                            <p>Klijenti:</p>
                            {training.clients?.map((client: any) => (
                                <p key={client.id}>{client.firstName} {client.lastName}</p>
                            ))}
                        </div>
                    )
                    )}

                </div>
            </>}
        </>
    );
}

export default SalariesComponent;