import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);

    const setUser = (userId) => {
        setUserId(userId);
    };

    return (
        <UserContext.Provider value={{ userId, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
