const handleInputChange = (event: any, data: any) => {
  return { ...data, [event.target.name]: event.target.value };
};

const handleCheckboxChange = (event: any, data: any, booleanResult = true) => {
  return {
    ...data,
    [event.target.name]: booleanResult
      ? event.target.checked
      : event.target.checked
      ? "1"
      : "0",
  };
};

const handleSelectChange = (event: any, data: any) => {
  return { ...data, [event.target.name]: event.target.value };
};

const handleEnterKeyPress = (event: any, func: Function) => {
  if (event.key === "Enter") func();
};

const isEmptyField = (formValues: any[], inputFields: any[]) => {
  for (let i = 0; i < inputFields.length; i++) {
    if (!formValues[i]?.trim().length) {
      inputFields[i].current.value = "";
      inputFields[i].current.setFocus();
      return true;
    }
  }
  return false;
};

const isInteger = (quantity: string) => {
  return quantity.match(/^[0-9]+$/);
};

const isDecimal = (quantity: string, numberOfDecimalPlaces: string) => {
  return quantity.match(
    new RegExp(/^\d+\.\d{2}$/.source.replace("2", numberOfDecimalPlaces))
  );
};

const DataHandlerHelper = {
  handleInputChange,
  handleCheckboxChange,
  handleSelectChange,
  handleEnterKeyPress,
  isEmptyField,
  isInteger,
  isDecimal,
};

export default DataHandlerHelper;
