import DataHandlerHelper from "../../helpers/DataHandler.helper";
import InitClientRegistrationLogic from "./ClientRegistration.logic";

const ClientRegistrationComponent: React.FC = () => {
    const { data, usernameRef, passwordRef, firstNameRef, lastNameRef, phoneRef, priceIndRef, price1IndRef, priceDuoRef, price1DuoRef, priceFamilyDuoRef, priceRefRef, onSubmit, onChange } = InitClientRegistrationLogic();
    return (
        <>
            <h1 className="text-align-center">Novi klijent</h1>
            <button type="button" onClick={onSubmit}>Sačuvaj</button>
            <br></br>
            <br></br>
            <label htmlFor="firstName">
                Ime
            </label>
            <br></br>
            <input placeholder="Unesi ime..." id="firstName" type={"text"} ref={firstNameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => lastNameRef.current.focus())} value={data.firstName} onChange={onChange} name="firstName" required></input>
            <br></br>
            <br></br>
            <label htmlFor="lastName">
                Prezime
            </label>
            <br></br>
            <input placeholder="Unesi prezime..." id="lastName" type={"text"} ref={lastNameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => usernameRef.current.focus())} value={data.lastName} onChange={onChange} name="lastName" required></input>
            <br></br>
            <br></br>
            <label htmlFor="lastName">
                Korisničko ime
            </label>
            <br></br>
            <input placeholder="Unesi korisničko ime..." id="username" type={"text"} ref={usernameRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => passwordRef.current.focus())} value={data.username} onChange={onChange} name="username" required></input>
            <br></br>
            <br></br>
            <label htmlFor="lastName">
                Lozinka
            </label>
            <br></br>
            <input placeholder="Unesi lozinku..." id="password" type={"text"} ref={passwordRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => phoneRef.current.focus())} value={data.password} onChange={onChange} name="password" required></input>
            <br></br>
            <br></br>
            <label htmlFor="phone">
                Broj telefona
            </label>
            <br></br>
            <input placeholder="Unesi broj telefona" id="phone" type={"text"} ref={phoneRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceIndRef.current.focus())} value={data.phone} onChange={onChange} name="phone" required></input>
            <br></br>
            <br></br>
            <label htmlFor="priceInd">
                Cena jednog individualnog treninga u paketu
            </label>
            <br></br>
            <input id="priceInd" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceIndRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => price1IndRef.current.focus())} value={data.priceInd} onChange={onChange} name="priceInd" required></input>
            <br></br>
            <br></br>
            <label htmlFor="price1Ind">
                Cena jednog individualnog treninga
            </label>
            <br></br>
            <input id="price1Ind" type={"number"} inputMode={"numeric"} min={0} step={1} ref={price1IndRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceDuoRef.current.focus())} value={data.price1Ind} onChange={onChange} name="price1Ind" required></input>
            <br></br>
            <br></br>
            <label htmlFor="priceDuo">
                Cena jednog duo treninga iz paketa
            </label>
            <br></br>
            <input id="priceDuo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceDuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => price1DuoRef.current.focus())} value={data.priceDuo} onChange={onChange} name="priceDuo" required></input>
            <br></br>
            <br></br>
            <label htmlFor="price1Duo">
                Cena jednog duo treninga
            </label>
            <br></br>
            <input id="price1Duo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={price1DuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceFamilyDuoRef.current.focus())} value={data.price1Duo} onChange={onChange} name="price1Duo" required></input>
            <br></br>
            <br></br>
            <label htmlFor="priceFamilyDuo">
                Cena jednog duo treninga iz porodičnog paketa
            </label>
            <br></br>
            <input id="priceFamilyDuo" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceFamilyDuoRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, () => priceRefRef.current.focus())} value={data.priceFamilyDuo} onChange={onChange} name="priceFamilyDuo" required></input>
            <br></br>
            <br></br>
            <label htmlFor="priceRef">
                Cena jednog reformer treninga iz paketa
            </label>
            <br></br>
            <input id="priceRef" type={"number"} inputMode={"numeric"} min={0} step={1} ref={priceRefRef} onKeyDown={e => DataHandlerHelper.handleEnterKeyPress(e, onSubmit)} value={data.priceRef} onChange={onChange} name="priceRef" required></input>
            <br></br>
            <br></br>
            <button type="button" onClick={onSubmit}>Sačuvaj</button>
        </>
    );
}

export default ClientRegistrationComponent;