import InitHomeClientLogic from "./HomeClient.logic";
import { useState } from "react";

const HomeClientComponent: React.FC = () => {
    const { client, onLogout, onTrainings, onCancel } = InitHomeClientLogic();
    const [activeTab, setActiveTab] = useState("Početna");
    const [showModal, setShowModal] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState<number | null>(null);

    // Helper function to format the date as requested
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('sr-RS', options); // Assuming Serbian locale
    };

    // Determine payment status based on client.counterRef
    const isPaid = client?.counterRef >= 0;

    const handleCancelClick = (id: number) => {
        setSelectedBookingId(id);
        setShowModal(true);
    };

    const confirmCancel = () => {
        if (selectedBookingId !== null) {
            onCancel(selectedBookingId);
        }
        setShowModal(false);
    };

    const closeModal = () => setShowModal(false);

    return (
        <div style={{ paddingBottom: '60px' }}> {/* Padding to avoid content overlap with fixed tabs */}
            {/* Modal for Confirmation */}
            {showModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '5px',
                        width: '300px',
                        textAlign: 'center'
                    }}>
                        <p>Da li ste sigurni da želite da otkažete ovaj trening?</p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px'
                        }}>
                            <button onClick={confirmCancel} style={{
                                padding: '10px 20px',
                                backgroundColor: '#2d862d', // Green for "Da"
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Da</button>
                            <button onClick={closeModal} style={{
                                padding: '10px 20px',
                                backgroundColor: '#ff5757', // Red for "Ne"
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Ne</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Paket and Broj treninga Section */}
            <h1 className="text-align-center">Paket</h1>
            <div style={{
                backgroundColor: '#f0f0f0',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 'bold'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <span>Reformer grupa</span>
                    <span style={{
                        backgroundColor: isPaid ? '#d4f0d4' : '#f8d4d4',
                        color: isPaid ? '#2d862d' : '#862d2d',
                        padding: '5px 10px',
                        borderRadius: '5px'
                    }}>
                        {isPaid ? 'Plaćeno' : 'Nije plaćeno'}
                    </span>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <span>Broj treninga</span>
                    <p>{client?.counterRef < 0 ? client?.counterRef : `${client?.counterRef}/10`}</p>
                </div>
            </div>

            {/* Zakazani treninzi Section */}
            <h1 className="text-align-center" style={{ marginTop: '20px' }}>Zakazani treninzi</h1>
            <div>
                {client?.bookings?.length ? (
                    client.bookings.map((booking: any, index: number) => (
                        <div key={index} style={{
                            backgroundColor: '#f0f0f0',
                            padding: '10px',
                            margin: '10px 0',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span>{formatDate(booking.date)}</span>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <span style={{
                                    backgroundColor: '#d4f0d4',
                                    color: '#2d862d',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    {new Date(booking.date).toLocaleTimeString('sr-RS', { hour: '2-digit', minute: '2-digit', timeZone: "UTC" })}
                                </span>
                                <span style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    padding: '0 10px', // Padding to ensure it doesn't touch edges
                                    overflow: 'hidden', // In case text is too long
                                    textOverflow: 'ellipsis', // Truncate long text with ellipsis
                                    whiteSpace: 'nowrap', // Prevent wrapping
                                }}>
                                    {booking.name}
                                </span>
                                <button type="button" style={{
                                    padding: '5px 10px',
                                    marginLeft: '10px', // To provide space between the name and button
                                    width: 'auto', // Button width adjusts to the text
                                    height: 'auto', // Ensure the button height matches the text
                                    fontSize: '14px', // Optional, to match the text size
                                    backgroundColor: '#ff5757',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }} onClick={() => { handleCancelClick(booking.id) }}>
                                    Otkaži
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{
                        textAlign: 'center',
                        color: '#999',
                        marginTop: '20px',
                    }}>
                        <span style={{ fontSize: '24px' }}>📅</span>
                        <p>Nemate zakazanih treninga</p>
                    </div>
                )}
            </div>

            {/* Bottom Navigation Tabs */}
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#fff',
                borderTop: '1px solid #ddd',
                display: 'flex',
                justifyContent: 'space-around',
                padding: '10px 0'
            }}>
                <button
                    style={{
                        fontWeight: activeTab === "Početna" ? 'bold' : 'normal',
                        color: activeTab === "Početna" ? '#2d862d' : '#555'
                    }}
                    onClick={() => setActiveTab("Početna")}
                >
                    Početna
                </button>
                <button
                    style={{
                        fontWeight: activeTab === "Kalendar" ? 'bold' : 'normal',
                        color: activeTab === "Kalendar" ? '#2d862d' : '#555'
                    }}
                    onClick={() => {
                        setActiveTab("Kalendar");
                        onTrainings();
                    }}
                >
                    Kalendar
                </button>
                <button
                    style={{
                        fontWeight: activeTab === "Odjavi se" ? 'bold' : 'normal',
                        color: activeTab === "Odjavi se" ? '#2d862d' : '#555'
                    }}
                    onClick={() => {
                        setActiveTab("Odjavi se");
                        onLogout();
                    }}
                >
                    Odjavi se
                </button>
            </div>
        </div>
    );
}

export default HomeClientComponent;
