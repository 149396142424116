import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";
import DataHandlerHelper from "../../helpers/DataHandler.helper";

const InitStopClientLogic = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    client: "",
    fromDate: "",
    toDate: "",
  });
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  const loadClients = () => {
    clientsApi
      .getClients()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onSave = () => {
    if (new Date(data.fromDate).toString() === "Invalid Date") {
      alert("Neispravan datum Od");
      return;
    }
    if (new Date(data.toDate).toString() === "Invalid Date") {
      alert("Neispravan datum Do");
      return;
    }
    if (!window.confirm("Sigurno želite da sačuvate?")) return;
    clientsApi
      .stop({
        selectedClients: selectedClients,
        fromDate: new Date(data.fromDate).toISOString(),
        toDate: new Date(data.toDate).toISOString(),
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          navigate("/home");
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  const addClient = (client: any) => {
    clients.splice(clients.indexOf(client), 1);
    setSelectedClients([...selectedClients, client]);
    setData({ ...data, client: "" });
  };

  const removeSelectedClient = (client: any) => {
    let removedSelectedClient = selectedClients.splice(
      selectedClients.indexOf(client),
      1
    );
    setSelectedClients([...selectedClients]);
    setClients([...clients, ...removedSelectedClient]);
  };

  useEffect(() => {
    loadClients();
  }, []);

  return {
    selectedClients,
    clients,
    data,
    onSave,
    onChange,
    addClient,
    removeSelectedClient,
  };
};

export default InitStopClientLogic;
