import jwt_decode from "jwt-decode";

interface DecodedToken {
  id: string;
  exp: number;
  iat: number;
  roles: any[];
}

const storeToken = (token: string) => {
  localStorage.setItem("access_token", token);
};

const removeToken = () => {
  localStorage.removeItem("access_token");
};

const getToken = () => {
  return localStorage.getItem("access_token") as string;
};

const getUserIdFromToken = () => {
  const token = localStorage.getItem("access_token"); // Pretpostavljamo da je token sačuvan u localStorage-u
  if (!token) {
    return null; // Ako nema tokena, vrati null
  }

  try {
    const decoded: DecodedToken = jwt_decode(token); // Dekodiraj token
    return decoded.id; // Vraća userId
  } catch (error) {
    console.error("Invalid token", error);
    return null; // Ako je token nevalidan, vrati null
  }
};

const getUserRolesFromToken = () => {
  const token = localStorage.getItem("access_token"); // Pretpostavljamo da je token sačuvan u localStorage-u
  if (!token) {
    return null; // Ako nema tokena, vrati null
  }

  try {
    const decoded: DecodedToken = jwt_decode(token); // Dekodiraj token
    return decoded.roles; // Vraća userId
  } catch (error) {
    console.error("Invalid token", error);
    return null; // Ako je token nevalidan, vrati null
  }
};

const tokenHelper = {
  storeToken,
  removeToken,
  getToken,
  getUserIdFromToken,
  getUserRolesFromToken,
};

export default tokenHelper;
