import React from 'react'
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import tokenHelper from '../../helpers/tokenHelper';
import ErrorPage from '../../pages/Error/ErrorPage';
import IRoute from '../../interfaces/route';

interface IPrivateRoute {
    route: IRoute
}

const PrivateRouteComponent: React.FunctionComponent<IPrivateRoute> = props => {
    let payloads: any;

    try {
        payloads = jwt_decode(tokenHelper.getToken());
    } catch (error) {
        payloads = {
            roles: []
        }
    }


    const haveAccess = (auth?: string[]) => {
        let allowedRoles = new Set(auth)
        for (let index = 0; index < payloads.roles?.length; index++) {
            if (allowedRoles.has(payloads.roles[index].name))
                return true;
        }
        return false;
        // return true;
    }

    const CreateRoute = (route: IRoute) => {
        if (route.auth !== undefined && tokenHelper.getToken() === null) {
            return <Navigate to="/" replace={true} />
        }
        else if (!haveAccess(route.auth) && route?.auth !== undefined) {
            tokenHelper.removeToken();
            return <ErrorPage code={"401"} error={"Sorry, you don't have permission to see this page."} />
        }
        // else if (route?.auth === undefined && tokenHelper.getToken() !== null
        //     && (route.allowLoggedUser === undefined || route.allowLoggedUser === false)) {
        //     return <Navigate to="/home-client" replace={true} />
        // }
        else {
            return <route.component  {...route.props} />
        }
    }

    return CreateRoute(props.route);
}

export default PrivateRouteComponent;