import client from "../client";

const doLogin = (props: any) => client.post<any>(`/login/doLogin`, props);

const loginClient = (props: any) => client.post<any>(`/login/client`, props);

const loginApi = {
  doLogin,
  loginClient,
};
export default loginApi;
