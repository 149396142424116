import { useEffect, useRef, useState } from "react";
import DataHandlerHelper from "../../helpers/DataHandler.helper";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";

const InitClientUpdateLogic = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>();
  const firstNameRef = useRef<any>();
  const lastNameRef = useRef<any>();
  const phoneRef = useRef<any>();
  const priceIndRef = useRef<any>();
  const price1IndRef = useRef<any>();
  const priceDuoRef = useRef<any>();
  const price1DuoRef = useRef<any>();
  const priceFamilyDuoRef = useRef<any>();
  const priceRefRef = useRef<any>();
  const [data, setData] = useState<any>({
    client: "",
  });

  const onSubmit = () => {
    if (!window.confirm("Sigurno želite da sačuvate?")) return;
    selectedClient.priceInd = +selectedClient.priceInd;
    selectedClient.price1Ind = +selectedClient.price1Ind;
    selectedClient.priceDuo = +selectedClient.priceDuo;
    selectedClient.price1Duo = +selectedClient.price1Duo;
    selectedClient.priceFamilyDuo = +selectedClient.priceFamilyDuo;
    selectedClient.priceRef = +selectedClient.priceRef;
    clientsApi
      .updateClient(selectedClient, selectedClient.id)
      .then((response) => {
        if (response.status === 204) {
          navigate("/home");
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setSelectedClient(
      DataHandlerHelper.handleInputChange(event, { ...selectedClient })
    );
  };

  const onChangeSearch = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  const loadClients = () => {
    clientsApi
      .getClients()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const selectClient = (client: any) => {
    clients.splice(clients.indexOf(client), 1);
    if (selectedClient !== undefined) setClients([...clients, selectedClient]);
    setSelectedClient(client);
    // setData({ ...data, client: "" });
  };

  const removeSelectedClient = (client: any) => {
    setSelectedClient(undefined);
    setClients([...clients, client]);
    setData({ ...data, client: "" });
  };

  useEffect(() => {
    loadClients();
  }, []);

  return {
    selectedClient,
    clients,
    data,
    firstNameRef,
    lastNameRef,
    phoneRef,
    priceIndRef,
    price1IndRef,
    priceDuoRef,
    price1DuoRef,
    priceFamilyDuoRef,
    priceRefRef,
    onSubmit,
    onChange,
    onChangeSearch,
    selectClient,
    removeSelectedClient,
  };
};

export default InitClientUpdateLogic;
