import { useState } from "react";
import paymentsApi from "../../api/payment/payment";
import DataHandlerHelper from "../../helpers/DataHandler.helper";

const InitPaymentsLogic = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [data, setData] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  const loadPayments = () => {
    if (new Date(data.fromDate).toString() === "Invalid Date") {
      alert("Neispravan datum Od");
      return;
    }
    if (new Date(data.toDate).toString() === "Invalid Date") {
      alert("Neispravan datum Do");
      return;
    }
    paymentsApi
      .getPaymentsBetween({
        fromDate: new Date(data.fromDate).toISOString(),
        toDate: new Date(data.toDate).toISOString(),
      })
      .then((response) => {
        if (response.status === 200) {
          setPayments(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  return {
    data,
    payments,
    onChange,
    loadPayments,
  };
};

export default InitPaymentsLogic;
