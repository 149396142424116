import DataHelper from "../../helpers/data.helper";
import InitPaymentsLogic from "./Payments.logic";

const PaymentsComponent: React.FC = () => {
    const { data, payments, onChange, loadPayments } = InitPaymentsLogic();
    return (
        <>
            <h1 className="text-align-center">Pregled uplata</h1>
            <label htmlFor="fromDate">
                Od
            </label>
            <br></br>
            <input id="fromDate" type={"date"} value={data.fromDate} onChange={onChange} name="fromDate"></input>
            <br></br>
            <br></br>
            <label htmlFor="toDate">
                Do
            </label>
            <br></br>
            <input id="toDate" type={"date"} value={data.toDate} onChange={onChange} name="toDate"></input>
            <br></br>
            <br></br>
            <button type="button" onClick={loadPayments}>Učitaj</button>
            <br></br>
            <br></br>
            {payments.length > 0 && <h3 className="text-align-center">Suma: {payments.reduce((acc, obj) => { return acc + obj.price; }, 0)} dinara</h3>}
            <br></br>
            <br></br>
            {payments.map((p) =>
                <div className="text-align-center" key={p.id}>{new Date(p.date).toLocaleString()}<br></br>{p.client.firstName} {p.client.lastName}<br></br>{DataHelper.trainingTypesIdsTrainingTypes[p.trainingTypeId]}<br></br><br></br>{p.price}<br></br><br></br><hr></hr><br></br></div>
            )}
        </>
    );
}

export default PaymentsComponent;