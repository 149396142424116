import React, { useState, useEffect, useMemo } from "react";
import InitTrainingsLogic from "./Bookings.logic";
import { FaClock, FaUsers, FaCalendarTimes } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./Bookings.module.css";

const BookingsComponent: React.FC = () => {
    const { client, bookings, onHome, onLogout, onBook, onCancel } = InitTrainingsLogic();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [filteredBookings, setFilteredBookings] = useState<any[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<any | null>(null);
    const [repeatDays, setRepeatDays] = useState<{ [key: string]: boolean }>({});
    const [activeTab, setActiveTab] = useState("Kalendar");
    const [showModal, setShowModal] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState<number | null>(null);

    const dayMappingAlwaysDisplayed: Record<"Mon" | "Tue" | "Wed" | "Thu" | "Fri", string> = useMemo(() => ({
        Mon: "Pon",
        Tue: "Uto",
        Wed: "Sre",
        Thu: "Cet",
        Fri: "Pet",
    }), []);

    const dayMapping: Record<"Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun", string> = useMemo(() => ({
        Mon: "Pon",
        Tue: "Uto",
        Wed: "Sre",
        Thu: "Cet",
        Fri: "Pet",
        Sat: "Sub",
        Sun: "Ned"
    }), []);

    const getAvailableDates = () => {
        // Set of dates with available bookings
        const availableDatesSet = new Set(
            bookings
                .filter((b) => !b.cancelled && (b.clients?.length || 0) < b.maxNumberOfClients)
                .map((b) => new Date(b.date).toLocaleDateString())
        );

        // Set of dates from client.bookings
        const clientBookingsDatesSet = new Set(
            client?.bookings?.map((b: any) => new Date(b.date).toLocaleDateString())
        );

        return { availableDatesSet, clientBookingsDatesSet };
    };

    const tileClassName = ({ date }: any) => {
        const formattedDate = date.toLocaleDateString();
        const { availableDatesSet, clientBookingsDatesSet } = getAvailableDates();

        // Proveri da li je datum selektovan i postavi plavu pozadinu
        if (formattedDate === selectedDate?.toLocaleDateString()) {
            return null; // Dodaj klasu za selektovani datum
        }

        if (clientBookingsDatesSet.has(formattedDate)) {
            return styles.orangeHighlightedDate; // Style for client bookings
        }
        if (availableDatesSet.has(formattedDate)) {
            return styles.highlightedDate; // Style for general available bookings
        }
        return null;
    };

    const handleDateClick = (date: Date) => {
        setSelectedDate(date);
        const selectedDateString = date.toLocaleDateString();

        // Get client-specific bookings for the selected date, sorted by date and time
        const clientBookingsForDate = client.bookings ?
            client.bookings.filter((b: any) => new Date(b.date).toLocaleDateString() === selectedDateString)
                .sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()) : [];

        // Get general bookings for the selected date, excluding those that are in client.bookings
        const generalBookings = bookings
            .filter((b) =>
                new Date(b.date).toLocaleDateString() === selectedDateString &&
                !(client.bookings ? client.bookings.some((cb: any) => cb.id === b.id) : false)
            );

        // Combine client-specific and general bookings
        setFilteredBookings([...clientBookingsForDate, ...generalBookings]);
    };




    const handleBookingClick = (booking: any) => {
        setSelectedBooking(booking);
        const selectedDayEng = new Date(booking.date).toLocaleDateString("en-US", {
            weekday: "short",
        }) as keyof typeof dayMapping;
        const selectedDay = dayMapping[selectedDayEng];
        // setRepeatDays((prev) => ({ ...prev, [selectedDay]: true }));
        setRepeatDays((prev) => ({ ...prev }));
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedBooking(null);
        setRepeatDays({});
    };

    const shouldShowSaturday = () => {
        if (selectedBooking) {
            const bookingDate = new Date(selectedBooking.date);
            const dayOfWeek = bookingDate.getUTCDay(); // 0 = Sunday, 6 = Saturday
            const hour = bookingDate.getUTCHours();

            // Proveri da li je subota i da li su sati u opsegu od 8 do 10
            return dayOfWeek === 6 && (hour >= 8 && hour <= 10);
        }
        return false;
    };


    const formatDateString = (date: Date) => {
        const day = date.toLocaleDateString("sr-Latn", { weekday: "long" });
        const dateString = date.toLocaleDateString("sr-Latn", { day: "numeric", month: "long", year: "numeric" });
        return `${day.charAt(0).toUpperCase() + day.slice(1)}, ${dateString}`;
    };

    const handleDayToggle = (day: string) => {
        setRepeatDays((prevState) => ({
            ...prevState,
            [day]: !prevState[day],
        }));
    };


    useEffect(() => {
        if (selectedBooking) {
            const selectedDayEng = new Date(selectedBooking.date).toLocaleDateString("en-US", {
                weekday: "short",
            }) as keyof typeof dayMapping;
            const selectedDay = dayMapping[selectedDayEng];
            /* setRepeatDays((prevState) => ({
                ...prevState,
                [selectedDay]: true,
            })); */
            setRepeatDays((prevState) => ({
                ...prevState,
            }));
        }
    }, [selectedBooking, dayMapping]);

    const handleCancelClick = (id: number) => {
        setSelectedBookingId(id);
        setShowModal(true);
    };

    const confirmCancel = () => {
        if (selectedBookingId !== null) {
            onCancel(selectedBookingId);
            setSelectedDate(null)
        }
        setShowModal(false);
    };

    const closeModalCancel = () => setShowModal(false);

    return (
        <div className={styles.container}>
            {/* <h1 className={styles.header}>Kalendar</h1> */}

            {/* Modal for Confirmation */}
            {showModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '5px',
                        width: '300px',
                        textAlign: 'center'
                    }}>
                        <p>Da li ste sigurni da želite da otkažete ovaj trening?</p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px'
                        }}>
                            <button onClick={confirmCancel} style={{
                                padding: '10px 20px',
                                backgroundColor: '#2d862d', // Green for "Da"
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Da</button>
                            <button onClick={closeModalCancel} style={{
                                padding: '10px 20px',
                                backgroundColor: '#ff5757', // Red for "Ne"
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Ne</button>
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.calendarContainer}>
                <Calendar
                    onClickDay={handleDateClick}
                    tileClassName={tileClassName}
                    view="month"
                    locale="sr-Latn"
                    minDate={new Date()}
                    className={styles.reactCalendar}
                />
            </div>

            <br />

            {selectedDate && (
                <div className={styles.selectedDateContainer}>
                    <h3>{formatDateString(selectedDate)}</h3>

                    {filteredBookings.length > 0 ? (
                        <div className={styles.bookingsList}>
                            {filteredBookings.map((b) => {
                                const isClientBooking = client.bookings ? client.bookings.some((cb: any) => cb.id === b.id) : false;

                                return (
                                    <div key={b.id} className={styles.bookingItem}>
                                        <div className={styles.bookingTime}>
                                            <FaClock className={styles.icon} />
                                            {new Date(b.date).toLocaleTimeString("sr-Latn", { hour: "2-digit", minute: "2-digit", timeZone: "UTC" })}
                                        </div>
                                        <div className={styles.bookingDetails}>
                                            <h4>{b.name}</h4>
                                            <p className={styles.bookingClientCount}>
                                                <FaUsers className={styles.icon} />
                                                Zauzeto: {b.clients ? b.clients.length : 0}/{b.maxNumberOfClients}
                                            </p>
                                        </div>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent parent onClick event if necessary
                                                isClientBooking ? handleCancelClick(b.id) : handleBookingClick(b); // Toggle based on booking type
                                            }}
                                            className={styles.scheduleButton}
                                            style={isClientBooking ? { backgroundColor: '#ff5757' } : {}}
                                        >
                                            {isClientBooking ? "Otkaži" : "Zakaži"}
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className={styles.noBookingsContainer}>
                            <FaCalendarTimes className={styles.noBookingsIcon} />
                            <h3 className={styles.noBookingsMessage}>Nema aktivnosti za izabrani datum</h3>
                            <p className={styles.noBookingsSubtext}>Za tvoj program nema treninga na ovaj dan</p>
                        </div>
                    )}
                </div>
            )}

            {modalVisible && selectedBooking && (
                <div className={styles.modalOverlay} onClick={closeModal}>
                    <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                        <h3>{`Trening: ${selectedBooking.name}`}</h3>
                        <p>{`Datum: ${new Date(selectedBooking.date).toLocaleDateString("sr-Latn")}`}</p>
                        <p>{`Vreme: ${new Date(selectedBooking.date).toLocaleTimeString("sr-Latn", {
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZone: "UTC"
                        })}`}</p>

                        <h4>Trening se ponavlja danima:</h4>
                        <div className={styles.repeatDaysContainer}>
                            {Object.keys(dayMappingAlwaysDisplayed).map((dayEng) => {
                                const daySrpski = dayMapping[dayEng as keyof typeof dayMapping];
                                return (
                                    <div key={daySrpski} className={styles.repeatDay}>
                                        <input
                                            type="checkbox"
                                            checked={repeatDays[daySrpski] || false}
                                            onChange={() => handleDayToggle(daySrpski)}
                                        />
                                        {daySrpski}
                                    </div>
                                );
                            })}
                            {shouldShowSaturday() && (
                                <div key="Sub" className={styles.repeatDay}>
                                    <input
                                        type="checkbox"
                                        checked={repeatDays["Sub"] || false}
                                        onChange={() => handleDayToggle("Sub")}
                                    />
                                    <label>Sub</label>
                                </div>
                            )}
                        </div>

                        <div className={styles.modalButtons}>
                            <button className={styles.saveButton} onClick={() => { onBook({ id: selectedBooking.id, repeatDays }); setSelectedDate(null); closeModal() }}>
                                Sačuvaj
                            </button>
                            <button className={styles.cancelButton} onClick={closeModal}>
                                Odustani
                            </button>
                        </div>
                    </div>
                </div>
            )
            }

            {/* Bottom Navigation Tabs */}
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#fff',
                borderTop: '1px solid #ddd',
                display: 'flex',
                justifyContent: 'space-around',
                padding: '10px 0'
            }}>
                <button
                    style={{
                        fontWeight: activeTab === "Početna" ? 'bold' : 'normal',
                        color: activeTab === "Početna" ? '#2d862d' : '#555'
                    }}
                    onClick={() => { setActiveTab("Početna"); onHome() }}
                >
                    Početna
                </button>
                <button
                    style={{
                        fontWeight: activeTab === "Kalendar" ? 'bold' : 'normal',
                        color: activeTab === "Kalendar" ? '#2d862d' : '#555'
                    }}
                    onClick={() => {
                        setActiveTab("Kalendar");
                    }}
                >
                    Kalendar
                </button>
                <button
                    style={{
                        fontWeight: activeTab === "Odjavi se" ? 'bold' : 'normal',
                        color: activeTab === "Odjavi se" ? '#2d862d' : '#555'
                    }}
                    onClick={() => {
                        setActiveTab("Odjavi se");
                        onLogout();
                    }}
                >
                    Odjavi se
                </button>
            </div>

        </div >
    );
};

export default BookingsComponent;
