import { useEffect, useState } from "react";
import DataHandlerHelper from "../../helpers/DataHandler.helper";
import usersApi from "../../api/user/user";

const InitSalariesLogic = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>();
  const [data, setData] = useState<any>({
    client: "",
  });

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  const loadClients = () => {
    usersApi
      .getUsers()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const selectClient = (client: any) => {
    clients.splice(clients.indexOf(client), 1);
    if (selectedClient !== undefined) setClients([...clients, selectedClient]);
    setSelectedClient(client);
    // setData({ ...data, client: "" });
  };

  const removeSelectedClient = (client: any) => {
    setSelectedClient(undefined);
    setClients([...clients, client]);
    setData({ ...data, client: "" });
  };

  useEffect(() => {
    loadClients();
  }, []);

  return {
    selectedClient,
    clients,
    data,
    onChange,
    selectClient,
    removeSelectedClient,
  };
};

export default InitSalariesLogic;
