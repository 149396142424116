import { useEffect, useRef, useState } from "react";
import loginApi from "../../api/login/login";
import DataHandlerHelper from "../../helpers/DataHandler.helper";
import { useNavigate } from "react-router-dom";
import tokenHelper from "../../helpers/tokenHelper";
import { useUser } from "../../context/UserContext";
import apiClient from "../../api/client";

const InitLoginClientLogic = () => {
  const navigate = useNavigate();
  const usernameRef = useRef<any>();
  const passwordRef = useRef<any>();
  const [data, setData] = useState<any>({ username: "", password: "" });
  const { setUser } = useUser();

  // Helper function to convert base64 to Uint8Array
  function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const subscribeToPushNotifications = async () => {
    const registration = await navigator.serviceWorker.ready;

    console.log(registration);

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        "BPtMPh59wNDqiC59GqrdGYX4occjNcOBGwYBGj_0_fUxMoxsRTD8lU4XOpys4cZGQ0VfcFp5LFvRprQIvd03RuY"
      ), // You'll need a public VAPID key
    });

    // console.log('auth:', arrayBufferToBase64(subscription.getKey('auth')));

    // console.log('p256dh:', arrayBufferToBase64(subscription.getKey('p256dh')));

    // console.log('Push Subscription:', subscription);

    // await client.post("/send-push-notification", { message: "aab", title: "idemo", subscription })

    // Send the subscription to your server to store it and send push notifications later
    await apiClient.post<any>(`/save-subscription`, subscription);
    /* await fetch('/api/save-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(subscription),
    }); */
  };

  // Function to request notification permission
  const requestNotificationPermission = async () => {
    if ("Notification" in window) {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted");
          // Call this function after the user has granted permission for notifications
          subscribeToPushNotifications()
            .then()
            .catch((e) => console.log(e));
        } else {
          console.log("Notification permission denied");
        }
      } catch (error) {
        console.error("Error requesting notification permission", error);
      }
    }
  };

  useEffect(() => {
    const userRoles = tokenHelper.getUserRolesFromToken();
    if (userRoles?.length) {
      if (userRoles.some((userRole) => userRole.name === "ROLE_CLIENT")) {
        navigate("/home-client");
      } else {
        navigate("/home");
      }
    }
  });

  const onSubmit = () => {
    loginApi
      .doLogin(data)
      .then((response) => {
        if (response.status === 200) {
          tokenHelper.storeToken(response.data.token);
          setUser(response.data.user.id);
          // Call this function when the app initializes or when the user is prompted
          requestNotificationPermission();
          if (
            response.data.user.roles.some(
              (role: any) => role.name === "ROLE_CLIENT"
            )
          ) {
            navigate("/home-client");
          } else {
            navigate("/home");
          }
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  return { data, usernameRef, passwordRef, onSubmit, onChange };
};

export default InitLoginClientLogic;
