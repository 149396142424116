const isDevEnv = false;
const isServerOnLocalhost = false;
const onAndroidEmulator = false;
const apiUrlIfServerIsNotOnLocalhost = "https://resetpilates.rs/api";
// const apiUrlIfServerIsNotOnLocalhost = "http://192.168.0.11:3000";

const adminUsername = "admin";

const Config = {
  dev: {
    apiUrl: isServerOnLocalhost
      ? !onAndroidEmulator
        ? "http://localhost:3000/api"
        : "http://10.0.2.2:3000/api"
      : apiUrlIfServerIsNotOnLocalhost,
  },
  prod: {
    apiUrl: isServerOnLocalhost
      ? !onAndroidEmulator
        ? "http://localhost:3000/api"
        : "http://10.0.2.2:3000/api"
      : apiUrlIfServerIsNotOnLocalhost,
  },
};

const getApiUrl = () => {
  return isDevEnv ? Config.dev.apiUrl : Config.prod.apiUrl;
};

const getAdminUsername = () => {
  return adminUsername;
};

const ConfigHelper = {
  getApiUrl,
  getAdminUsername,
};

export default ConfigHelper;
