import client from "../client";

const savePayment = (props: any) => client.post<any>(`/payments`, props);

const getPaymentsBetween = (props: any) =>
  client.post<any>(`/payments/between`, props);

const paymentApi = {
  savePayment,
  getPaymentsBetween,
};
export default paymentApi;
