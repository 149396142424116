const trainingTypesIdsClientCounters: any = {
  2: "counterInd",
  3: "counter1Ind",
  4: "counterDuo",
  5: "counter1Duo",
  6: "counterFamilyDuo",
  1: "counterRef",
};

const trainingTypesIdsQuantities: any = {
  2: 10,
  3: 1,
  4: 10,
  5: 1,
  6: 10,
  1: 10,
};

const trainingTypesIdsClientPrices: any = {
  2: "priceInd",
  3: "price1Ind",
  4: "priceDuo",
  5: "price1Duo",
  6: "priceFamilyDuo",
  1: "priceRef",
};

const trainingTypesIdsTrainingTypesForUser: any = {
  2: "Individualni",
  3: "Individualni",
  4: "Duo",
  5: "Duo",
  6: "Duo",
  1: "Reformer grupa",
};

const trainingTypesIdsTrainingTypes: any = {
  2: "Individualni paket",
  3: "1 individualni trening",
  4: "Duo paket",
  5: "1 duo trening",
  6: "Duo porodični paket",
  1: "Reformer grupa",
};

const DataHelper = {
  trainingTypesIdsClientPrices,
  trainingTypesIdsQuantities,
  trainingTypesIdsClientCounters,
  trainingTypesIdsTrainingTypesForUser,
  trainingTypesIdsTrainingTypes,
};

export default DataHelper;
