import InitStopAllLogic from "./StopAll.logic";

const StopAllComponent: React.FC = () => {
    const { data, onSave, onChange } = InitStopAllLogic();
    return (
        <>
            <h1 className="text-align-center">Stopiraj paket svima</h1>
            <label htmlFor="fromDate">
                Od
            </label>
            <br></br>
            <input id="fromDate" type={"date"} value={data.fromDate} onChange={onChange} name="fromDate"></input>
            <br></br>
            <br></br>
            <label htmlFor="toDate">
                Do
            </label>
            <br></br>
            <input id="toDate" type={"date"} value={data.toDate} onChange={onChange} name="toDate"></input>
            <br></br>
            <br></br>
            <button type="button" onClick={onSave}>Sačuvaj</button>
        </>
    );
}

export default StopAllComponent;