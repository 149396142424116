import { useEffect, useState } from "react";
import clientsApi from "../../api/client/client";

const InitClientsInMinusLogic = () => {
  const [clients, setClients] = useState<any[]>([]);

  const loadClients = () => {
    clientsApi
      .getClients()
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadClients();
  }, []);

  return {
    clients,
  };
};

export default InitClientsInMinusLogic;
