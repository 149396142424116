import { useEffect, useRef, useState } from "react";
import DataHandlerHelper from "../../helpers/DataHandler.helper";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";
import trainingTypeApi from "../../api/trainingType/trainingType";
import DataHelper from "../../helpers/data.helper";

const InitClientRegistrationLogic = () => {
  const navigate = useNavigate();
  // const [trainingsTypes, setTrainingsTypes] = useState<any[]>([]);
  const usernameRef = useRef<any>();
  const passwordRef = useRef<any>();
  const firstNameRef = useRef<any>();
  const lastNameRef = useRef<any>();
  const phoneRef = useRef<any>();
  const priceIndRef = useRef<any>();
  const price1IndRef = useRef<any>();
  const priceDuoRef = useRef<any>();
  const price1DuoRef = useRef<any>();
  const priceFamilyDuoRef = useRef<any>();
  const priceRefRef = useRef<any>();
  const [data, setData] = useState<any>({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    phone: "",
    priceInd: 0,
    price1Ind: 0,
    priceDuo: 0,
    price1Duo: 0,
    priceFamilyDuo: 0,
    priceRef: 0,

    counterInd: 0,
    counter1Ind: 0,
    counterDuo: 0,
    counter1Duo: 0,
    counterFamilyDuo: 0,
    counterRef: 0,
  });

  const onSubmit = () => {
    if (!window.confirm("Sigurno želite da dodate novog klijenta?")) return;
    data.priceInd = +data.priceInd;
    data.price1Ind = +data.price1Ind;
    data.priceDuo = +data.priceDuo;
    data.price1Duo = +data.price1Duo;
    data.priceFamilyDuo = +data.priceFamilyDuo;
    data.priceRef = +data.priceRef;
    data.credit = 0;
    clientsApi
      .registerClient(data)
      .then((response) => {
        if (response.status === 200) {
          navigate("/home");
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onChange = (event: any) => {
    setData(DataHandlerHelper.handleInputChange(event, { ...data }));
  };

  const loadTrainingsTypes = () => {
    trainingTypeApi
      .getTrainingTypes()
      .then((response) => {
        if (response.status === 200) {
          // setTrainingsTypes(response.data);
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            data[DataHelper.trainingTypesIdsClientPrices[element.id]] =
              element.price;
          }
          setData({ ...data });
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadTrainingsTypes();
  }, []);

  return {
    data,
    usernameRef,
    passwordRef,
    firstNameRef,
    lastNameRef,
    phoneRef,
    priceIndRef,
    price1IndRef,
    priceDuoRef,
    price1DuoRef,
    priceFamilyDuoRef,
    priceRefRef,
    onSubmit,
    onChange,
  };
};

export default InitClientRegistrationLogic;
