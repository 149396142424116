import { useEffect, useState } from "react";
import bookingApi from "../../api/booking/booking";
import tokenHelper from "../../helpers/tokenHelper";
import { useNavigate } from "react-router-dom";
import clientsApi from "../../api/client/client";
import apiClient from "../../api/client";

const InitTrainingsLogic = () => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState<any[]>([]);

  const [client, setClient] = useState<any>();

  const loadClient = () => {
    clientsApi
      .getMe()
      .then((response) => {
        if (response.status === 200) {
          setClient(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  useEffect(() => {
    loadClient();
  }, []);

  useEffect(() => {
    loadBookings();
  }, []);

  const loadBookings = () => {
    bookingApi
      .getBookingsForClients()
      .then((response) => {
        if (response.status === 200) {
          setBookings(response.data);
        }
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onBook = (data: any) => {
    bookingApi
      .book(data)
      .then(() => {
        loadBookings();
        loadClient();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  const onLogout = async () => {
    await apiClient.post<any>(`/delete-subscription`);
    tokenHelper.removeToken();
    navigate("/");
  };

  const onHome = () => {
    navigate("/home-client");
  };

  const onCancel = (id: number) => {
    bookingApi
      .cancel({ id })
      .then(() => {
        loadBookings();
        loadClient();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      });
  };

  return {
    client,
    bookings,
    onHome,
    onLogout,
    onBook,
    onCancel,
  };
};

export default InitTrainingsLogic;
