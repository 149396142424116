import InitClientsInMinusLogic from "./ClientsInMinus.logic";

const ClientsInMinusComponent: React.FC = () => {
    const { clients } = InitClientsInMinusLogic();
    return (
        <>
            <h1 className="text-align-center">Pregled dužnika</h1>
            {clients.map((c) => (c.counterInd < 0 || c.counter1Ind < 0 || c.counterDuo < 0 || c.counter1Duo < 0 || c.counterFamilyDuo < 0 || c.counterRef < 0) ? <div className={"text-align-center"} key={c.id}>{c.firstName} {c.lastName}<br></br>
                <div>{c.phone}</div>
                {c.counterInd < 0 && <div>Broj individualnih treninga iz paketa: {c.counterInd}</div>}
                {c.counter1Ind < 0 && <div>Broj pojedinačnih individualnih treninga: {c.counter1Ind}</div>}
                {c.counterDuo < 0 && <div>Broj duo treninga iz paketa: {c.counterDuo}</div>}
                {c.counter1Duo < 0 && <div>Broj pojedinačnih duo treninga: {c.counter1Duo}</div>}
                {c.counterFamilyDuo < 0 && <div>Broj duo treninga iz porodičnog paketa: {c.counterFamilyDuo}</div>}
                {c.counterRef < 0 && <div>Broj treninga iz reformer paketa: {c.counterRef}</div>}
                <br></br>
                <hr></hr>
                <br></br>
            </div> : "")
            }
        </>
    );
}

export default ClientsInMinusComponent;